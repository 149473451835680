import React from 'react'
import StarNightCard from './StarNightCard'

const StarNight = () => {
    return (
        <div>
            <h1>StarNight</h1>
            <StarNightCard day="Day 1" imageURL="//unsplash.it/500/500" data="Testing 12" />
            <StarNightCard day="Day 2" imageURL="//unsplash.it/300/300" data="Be it be" />
            <StarNightCard day="Day 3" imageURL="//unsplash.it/200/200" data="FOO BAR" />
        </div>
    )
}

export default StarNight