import React from 'react';
import delicon from "../Images/Del.png"
import './ImageCard.css';
const ImageCard = (params) => {
    return (
        <div className='ImageCard'>
            <img src={params.imageURL} alt="" />
            <div className='DeleteButton'><img src={delicon} alt="" /></div>
        </div>
    )
}

export default ImageCard