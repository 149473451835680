import React from 'react'

const EventEdit = () => {
    return (
        <div>
            <h1>Official Events</h1>
            <div className="form-group">
                <h3>Name</h3>
                <input type="text" className='form-control' placeholder='Event Name' />
                <h3>Description</h3>
                <textarea id="" cols="30" rows="10" placeholder='description' className='form-control'></textarea>
                <h3>Event Picture</h3>
                <input type="file" className='form-control' />
            </div>
            <div className="bg-image">
                <img src="" alt="" />
            </div>
            <div className='d-flex justify-content-end'>
                <a href="/background/edit" className='btn btn-primary mt-3 text-light'>Update</a>
                <a href="" className='btn btn-danger mt-3 ml-3 text-light'>Delete</a>
            </div>
        </div>
    )
}

export default EventEdit