import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import './App.css'
import MainBar from "./components/MainBar";
import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
const App = () => {
  return <div>
    <Navbar />
    <Sidebar />
    <MainBar />
  </div>;
};

export default App;