import React from 'react'
import './EditEvent.css'
import axios from "axios"
const EditEvent = () => {
    const baseUrl = "https://gates-api.herokuapp.com/api/v1";
    const access = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imx1Y2FzIiwibG9naW5UaW1lIjoiMjAyMi0wNC0yMVQwNDozMzo1OC4wMzNaIiwiaWF0IjoxNjUwNTE1NjM4LCJleHAiOjE2NTA2MDIwMzh9.p0gAJQHVqAREYleKzyJv-tNjlK5oGgicZxTJRbWP_WE";
    const config = {
        headers: {
            Authorization: "Bearer " + access,
        }
    };
    const data = {
        name: "WEBBB DD SEGA",
        society: "DSC",
        date: "2022-04-16T06:53:12.935Z",
        category: "CULTURAL",
        description: "some WEBBSS event"
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const d = new Date();
        console.log(d.toISOString());
        data.name = e.target[6].value;
        data.society = e.target[7].value;
        data.description = e.target[8].value;
        for (let i = 0; i < 6; i++) {
            if (e.target[i].checked) {
                data.category = e.target[i].value;
            }
        }
        data.date = d.toISOString();
        await axios.post(baseUrl + "/event", data, config).then(result => console.log(result)).catch(err => console.log(err));
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className='form-group'>
                <h1>Add Event</h1>
                <h3 className='mt-5'>Event Categories</h3>
                <div className='form-row'>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="OFFICIAL" id="flexRadioDefault1" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Official
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="TECHNICAL" id="flexRadioDefault1" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Technical
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="CULTURAL" id="flexRadioDefault2" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Cultural
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="DIVINE" id="flexRadioDefault2" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Divine
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="MANAGEMENT" id="flexRadioDefault2" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Management
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="MISCELLANEOUS" id="flexRadioDefault2" name="flexRadioDefault1" required />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Miscellaneous
                        </label>
                    </div>
                </div>
                <div className='form-group'>
                    <h1>Event Name</h1>
                    <input type="text" className='form-control mt-3 mb-5' placeholder='Enter Name' />
                    <h1>Society Name</h1>
                    <input type="text" className='form-control mt-3 mb-5' placeholder='Add Image' />

                    <h1>Description</h1>
                    <textarea name="" id="" cols="30" rows="5" className='form-control mb-5' placeholder='Description'></textarea>
                    <h1>Start Date</h1>
                    <input type="date" className='form-control mt-3 mb-5' placeholder='Add Image' />
                </div>
                <div className='d-flex justify-content-start'>
                    <button type="submit" className='btn btn-primary mt-3 '>Add</button>
                </div>
            </div>
        </form>
    )
}

export default EditEvent