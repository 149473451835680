import React from 'react'
import "./Background.css"
const BackgroundEdit = () => {
    return (
        <div>
            <h1>Background Image</h1>
            <div className="bg-image">
                <img src="" alt="" />
            </div>
            <div className='form-group mt-5'>
                <h3 className='mb-3'>Choose Background Image</h3>
                <input type="file" className='form-control mb-5' />
            </div>
            <div className='d-flex justify-content-end'>
                <a href="" className='btn btn-primary mt-3 text-light'>Update</a>
                <a href="/background" className='btn btn-danger mt-3 ml-3 text-light'>Cancel</a>
            </div>
        </div>
    )
}

export default BackgroundEdit