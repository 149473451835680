import React from 'react'

const EditContact = () => {
    return (
        <div>
            <h1>Add Contact</h1>
            <div className="form-row">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                        General Information Contact
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                        Technical
                    </label>
                </div>
            </div>
            <div className="form-row">
                <div className='m-3'>
                    <h3>Name</h3>
                    <input type="text" className='form-control' placeholder='Enter Name' />
                </div>
                <div className='m-3'>
                    <h3>Profile Picture</h3>
                    <input type="file" className='form-control' placeholder='Add Image' />
                </div>
            </div>
            <div className="form-row">
                <div className='m-3'>
                    <h3>Email</h3>
                    <input type="text" className='form-control' placeholder='Enter Email Address' />
                </div>
                <div className='m-3'>
                    <h3>Mobile Number</h3>
                    <input type="text" className='form-control' placeholder='Enter Mobile Number' />
                </div>

            </div>
            <div className='d-flex justify-content-end mt-3'>
                <a className='btn btn-primary m-3 text-light' href=''>Add</a>
                <a className='btn btn-danger m-3 text-light' href='/contacts'>Cancel</a>
            </div>
        </div>
    )
}

export default EditContact