import React from 'react'
import './contact.css'
import edit from '../Images/Edit.png'
import del from '../Images/Delete.png'
const ContactCard = () => {
    return (
        <div>
            <div className="ContactCard">
                <div className="ContactImage">
                    <img src="//unsplash.it/500/500" alt="" />
                </div>
                <div className="ContactText">
                    <h3>Ashish Sharma</h3>
                    <p>+91 9911987961</p>
                </div>
                <div className='ContactEdit'>
                    <a href="/contacts/edit"><img src={edit} alt="" /></a>
                    <a href=""><img src={del} alt="" /></a>
                </div>
            </div>
        </div>
    )
}

export default ContactCard