import React from 'react';
import edit from '../Images/Edit.png';
import del from '../Images/Delete.png';
import axios from "axios";
const EditSponsors = () => {
    const baseUrl = "https://gates-api.herokuapp.com/api/v1";
    const access = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imx1Y2FzIiwibG9naW5UaW1lIjoiMjAyMi0wNC0yMVQwNDozMzo1OC4wMzNaIiwiaWF0IjoxNjUwNTE1NjM4LCJleHAiOjE2NTA2MDIwMzh9.p0gAJQHVqAREYleKzyJv-tNjlK5oGgicZxTJRbWP_WE";
    const config = {
        headers: {
            Authorization: "Bearer " + access,
        }
    };
    const data = {
        name: "as231 com",
        photoLink: "somelink.com",
        category: "ASSOCIATE"
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        data.name = e.target[4].value;
        data.photoLink = e.target[5].value;
        for (let i = 0; i < 4; i++) {
            if (e.target[i].checked) {
                data.category = e.target[i].value;
            }
        }
        // data.category = "ASSOCIATE";
        console.log(data);
        await axios.post(baseUrl + "/sponser", data, config).then(result => console.log(result)).catch(err => console.log(err));
    }
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <h1>Sponsors</h1>
                <div className='form-row'>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="TITLE" />
                        <label class="form-check-label" for="flexRadioDefault1">
                            Title Sponsor
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="CO-SPONSER" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Co-Sponsor
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="ASSOCIATE" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Associate Sponsor
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="EVENT" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            Event Sponsor
                        </label>
                    </div>
                </div>
                <div className='form-group'>
                    <h3>Name of Sponsor</h3>
                    <input type="text" name="Name" id="" className='form-control' placeholder='Enter Name' />
                    <h3>Logo</h3>
                    <input type="url" placeholder='Add Image' className='form-control' name='File' required />
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-primary mt-3 text-light' type='submit'>Update</button>
                    <a href="" className='btn btn-danger mt-3 ml-3 text-light'>Cancel</a>
                </div>
            </div>
        </form>
    )
}

export default EditSponsors