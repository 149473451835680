import React from 'react'
import ImageCard from './ImageCard'

const Gallery = () => {
    return (
        <div>
            <div className="form-group">
                <h1>Gallery</h1>
                <input type="text" placeholder='Add Image' />
                <button className='btn btn-primary'>Add</button>
            </div>
            <h1>Added Images</h1>
            <div className='GalleryContainer'>
                <ImageCard imageURL="//unsplash.it/400/400" />
                <ImageCard imageURL="//unsplash.it/400/400" />
                <ImageCard imageURL="//unsplash.it/400/400" />
                <ImageCard imageURL="//unsplash.it/400/400" />
            </div>
        </div>
    )
}

export default Gallery