import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import './sidebar.css'
const Sidebar = () => {

  return (
    <section class="side-bar">
      <ul>
        <div className='mb-3'>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              Home Page
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/background">
                Background
              </Dropdown.Item>
              <Dropdown.Item href="/dates">
                Date
              </Dropdown.Item>
              <Dropdown.Item href="/trailer">
                Trailer
              </Dropdown.Item>
              <Dropdown.Item href="/contacts">
                Contacts
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='mb-3'>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              About
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/about/students">
                Event & Students No.
              </Dropdown.Item>
              <Dropdown.Item href="/about/events">
                Official GATES Events
              </Dropdown.Item>
              <Dropdown.Item href="/about/gallery">
                Gallery
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <li><a href="/starnight">StarNight</a></li>
        <li><a href="/sponsors">Sponsors</a></li>
        <li><a href="/events">Events</a></li>
      </ul>
    </section>
  )
}

export default Sidebar
