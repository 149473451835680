import React from 'react'
import './Background.css'
const Background = () => {
    return (
        <div>
            <h1>Background Image</h1>
            <div className="bg-image">
                <img src="" alt="" />
            </div>
            <div className='d-flex justify-content-end'>
                <a href="/background/edit" className='btn btn-primary mt-3 text-light'>Update</a>
                <a href="" className='btn btn-danger mt-3 ml-3 text-light'>Delete</a>
            </div>
        </div>
    )
}

export default Background