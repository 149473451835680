import React from 'react'
import EventCard from './EventCard'

const GatesEvents = () => {
    return (
        <div>
            <h1>Official Gates Events</h1>
            <div className='eventContainer'>
                <EventCard day="Day 1" imageURL="//unsplash.it/500/500" data="Testin 45" />
                <EventCard day="Day 2" imageURL="//unsplash.it/600/600" data="Meow 2" />
                <EventCard day="Day 3" imageURL="//unsplash.it/700/700" data="Events" />
            </div>
        </div>
    )
}

export default GatesEvents