import React from 'react'

const EventsStudents = () => {
  return (
    <div>
      <div className="form-group">
        <div>
          <h1 className='mb-2'>Number of Events:</h1>
          <input type="text" className='p-1' />
          <button className='btn btn-primary ml-2'>Update</button>
        </div>
        <div>
          <h1 className='mb-2 mt-5'>Number of Students:</h1>
          <input type="text" className='p-1' />
          <button className='btn btn-primary ml-2'>Update</button>
        </div>
      </div>
    </div>
  )
}

export default EventsStudents