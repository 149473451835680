import React from 'react'
import './EventCard.css'
import edit from '../Images/Edit.png'
import del from '../Images/Delete.png'
const EventCard = (params) => {
    return (
        <div className='EventCard'>
            <div className="EventImage">
                <img src={params.imageURL} alt="" />
            </div>
            <div className='EventContent'>
                <h1>{params.day}</h1>
                <p>{params.data}</p>
            </div>
            <div className='EditDelete'>
                <a href="/about/events/edit"><img src={edit} alt="" /></a>
                <a href=""><img src={del} alt="" /></a>
            </div>

        </div >
    )
}

export default EventCard;