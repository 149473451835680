import { React, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import EventsStudents from './About/EventsStudents';
import Gallery from './About/Gallery';
import GatesEvents from './About/GatesEvents';
import Card from './Card';
import Date from './HomePage/Date';
import EditEvent from './Events/EditEvent';
import Events from './Events/Events';
import './mainbar.css'
import Trailer from './HomePage/Trailer';
import Background from './HomePage/Background';
import Contacts from './HomePage/Contacts';
import StarNight from './StarNight/StarNight';
import Sponsors from './sponsors/Sponsors';
import BackgroundEdit from './HomePage/BackgroundEdit';
import EventEdit from './About/EventEdit';
import EditContact from './HomePage/EditContact';
import EditSponsors from './sponsors/EditSponsors';
const MainBar = () => {
    const [active, setActive] = useState("FirstCard");
    return (
        <div className='main-body'>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <Background />
                    </Route>
                    <Route path="/background" exact>
                        <Background />
                    </Route>
                    <Route path="/background/edit" exact>
                        <BackgroundEdit />
                    </Route>
                    <Route path="/trailer" exact>
                        <Trailer />
                    </Route>
                    <Route path="/dates" exact>
                        <Date />
                    </Route>
                    <Route path="/contacts" exact>
                        <Contacts />
                    </Route>
                    <Route path="/contacts/edit" exact>
                        <EditContact />
                    </Route>
                    <Route path="/events" exact>
                        <Events />
                    </Route>
                    <Route path="/event/edit" exact>
                        <EditEvent />
                    </Route>
                    <Route path="/about/students" exact>
                        <EventsStudents />
                    </Route>
                    <Route path="/about/events" exact>
                        <GatesEvents />
                    </Route>
                    <Route path="/about/events/edit" exact>
                        <EventEdit />
                    </Route>
                    <Route path="/about/gallery" exact>
                        <Gallery />
                    </Route>
                    <Route path="/starnight" exact>
                        <StarNight />
                    </Route>
                    <Route path="/sponsors" exact>
                        <Sponsors />
                    </Route>
                    <Route path="/sponsors/edit" exact>
                        <EditSponsors />
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </Router>

        </div>
    )
}

export default MainBar