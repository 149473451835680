import React from 'react'
import ContactCard from './ContactCard'
const Contacts = () => {

    return (
        <div>
            <div className="form-group">
                <h1>General Contact Information</h1>
                <a href="" className='btn btn-primary'>Add</a>
            </div>
            <div className='MainBarContainer'>
                <ContactCard />
                <ContactCard />
                <ContactCard />
                <ContactCard />
            </div>
            <h1>Sponsors Contact</h1>
            <a href="" className='btn btn-primary'>Add</a>
            <div className='MainBarContainer'>
                <ContactCard />
                <ContactCard />
                <ContactCard />
                <ContactCard />
                <ContactCard />
            </div>
        </div>
    )
}

export default Contacts