import React, { useState } from "react";
import "./sponsor.css";
import axios from "axios";
const Sponsors = () => {
  const baseUrl = "https://gates-api.herokuapp.com/api/v1";
  const access = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imx1Y2FzIiwibG9naW5UaW1lIjoiMjAyMi0wNC0yMVQwNDozMzo1OC4wMzNaIiwiaWF0IjoxNjUwNTE1NjM4LCJleHAiOjE2NTA2MDIwMzh9.p0gAJQHVqAREYleKzyJv-tNjlK5oGgicZxTJRbWP_WE";
  const config = {
    headers: {
      Authorization: "Bearer " + access,
    }
  };
  let Title = [];
  const [Associate, setAssociate] = useState();

  const getSponsors = async () => {
    let sponsors;
    await axios.get(baseUrl + "/sponser", config).then(data => {
      data.data.sponsers.forEach(element => {
        if (element.category === "TITLE") {
          Title.push(element);
        }
        else if (element.category === "ASSOCIATE") {
          setAssociate(element);
        }
      });

    })
      .catch(err => console.log(err))
  }
  getSponsors();
  return (
    <>
      <div className="sponsorContainer">
        <h1 className="">Sponsor</h1>
        <div className="brochure">
          <div>
            <label htmlFor="file">Choose file to upload</label>
            <input type="file" id="file" name="file" multiple className="form-control" />
          </div>
          <div>
            <a className="btn btn-primary text-light" href="/sponsors/edit">Add</a>
          </div>
        </div>
        <div className="sponsorHead">
          <h1>Title Sponsor</h1>
          <a className="btn btn-primary text-light" href="/sponsors/edit">Add</a>
        </div>
        <ul className="sponsorType">
          <li>Haldirams</li>
          <li>Haldirams</li>
          <li>Haldirams</li>
        </ul>
        <div className="sponsorHead">
          <h1>Co-Sponsor</h1>
          <a className="btn btn-primary text-light" href="/sponsors/edit">Add</a>
        </div>
        <ul className="sponsorType">
          <li>Haldirams</li>
          <li>Haldirams</li>
          <li>Haldirams</li>
        </ul>
      </div>
    </>
  );
};

export default Sponsors;
